import * as React from 'react';

export const Mileage = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Mileage Small</title>
    <path
      d="M8.5 19.5h-7v-15h7v15Zm0-15h7v15h-7v-15Zm7 0h7v15h-7v-15Zm7.5-3H1h22Zm-22 21h22H1Zm2-13h3.2l-2.6 5.2M14 9.5h-3.5v2.6s2.9 0 2.9 1.7-3.2.9-3.2.9m7.3 1.3c.5-.4 1.1-.6 1.7-.6 1.1.1 1.3 1.2 1.1 1.8-.4 1-1.1 2.3-2.8 2.3m2-15V9"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
