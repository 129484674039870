import * as React from 'react';

export const Idea = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Idea Small</title>
    <path
      d="M10 21.5h4m-5-3h6M10 16l-1.239-1.345A6.509 6.509 0 0 1 5.5 9.01C5.5 5.415 8.41 2.5 12 2.5s6.5 2.915 6.5 6.51a6.509 6.509 0 0 1-3.261 5.645L14 16"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
