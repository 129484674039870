import * as React from 'react';

export const Administrator = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Administrator Small</title>
    <path
      d="M9.5 17.5V21L7 23.5H4l-.5-.5L5 21.5l.5-1-2-2-1 .5L1 20.5.5 20v-3L3 14.5h3.5l8-8V3L17 .5h3l.5.5-1.8 1.7-.2.8 2 2 .8-.2L23 3.5l.5.5v3L21 9.5h-3.5z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
