import * as React from 'react';

export const Projects = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Projects Small</title>
    <path
      d="M2.5 9.5h18c.6 0 1 .4 1 1v13h-18c-.6 0-1-.4-1-1v-13Zm12.5 10h4-4Zm-5 0h4-4Zm-5 0h4-4Zm10-3h4-4Zm-5 0h4-4Zm-5 0h4-4Zm10-3h4-4Zm-5 0h4-4Zm-5 0h4-4Zm13.6-4-5.2-6.3m-3.8 0L4.4 9.5m7.1-5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
