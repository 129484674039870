import * as React from 'react';

export const Login = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Login Small</title>
    <path
      d="M14.8 10.5c.8-2.3 3.4-3.6 5.7-2.7 1.8.6 3 2.3 3 4.2 0 2.5-2 4.5-4.5 4.5-1.9 0-3.6-1.2-4.2-3h-1.3l-1.9 1.1-1.9-1.3-2 1.3-2.1-1.3-1.9 1.3h-.9l-2-2.1 2.1-1.9h11.9v-.1Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
