import * as React from 'react';

export const Warranty = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Warranty Small</title>
    <path
      d="m17.255 12.78 2.398 8.364-2.647-1.062-1.678 2.302-2.136-7.45m-1.88.001-2.136 7.45-1.682-2.304-2.643 1.063 2.397-8.361M12.25 15a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Zm-3-6.706 2.15 2.1L15.248 5.5"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
