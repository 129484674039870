import styled from 'styled-components';
import { getResponsiveSpacing, createMediaQuery } from '../../utils';

export const GroupContainer = styled.ul`
  box-sizing: border-box;
  width: calc(100%-30px);
  padding: 0px;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: column;
  ${(props) =>
    createMediaQuery({
      margin: {
        xs: '0px 15px 60px 15px',
        l: `0px ${getResponsiveSpacing('xxxl')(props)} ${getResponsiveSpacing('xxxl')(props)}`,
        xl: `0px 60px ${getResponsiveSpacing('xxxl')(props)}`,
      },
      display: {
        xs: 'flex',
        m: 'grid',
      },
      'column-gap': {
        m: `${getResponsiveSpacing('m')(props)}`,
        xl: `${getResponsiveSpacing('l')(props)}`,
      },
      width: {
        l: 'calc(100% - 120px)',
      },
    })}

  & a {
    ${(props) =>
      createMediaQuery({
        'margin-top': {
          xs: `${getResponsiveSpacing('xs')(props)}`,
          m: `${getResponsiveSpacing('m')(props)}`,
          xl: `${getResponsiveSpacing('l')(props)}`,
        },
      })}
  }

  & a:first-child {
    ${(props) =>
      createMediaQuery({
        'margin-top': {
          xs: `${getResponsiveSpacing('s')(props)}`,
          m: `${getResponsiveSpacing('m')(props)}`,
          xl: `${getResponsiveSpacing('l')(props)}`,
        },
      })}
  }
`;
