import * as React from 'react';

export const Catalogue = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Catalogue Small</title>
    <path
      d="M22.5 7v13.5h-19l17-3V4m-19 2 15.75-4.07a1 1 0 0 1 1.25.968V11.5l-4 4-11.74 3.16a1 1 0 0 1-1.26-.965V6Zm13 9.5v-4h4"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
