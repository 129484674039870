import * as React from 'react';

export const User = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>User Small</title>
    <path
      d="M17.5 13.5c.6 0 1 .4 1 1v7h-13v-7c0-.6.4-1 1-1h11Zm-4-10c1.1 0 2 .9 2 2v3c0 1.1-.9 2-2 2h-3c-1.1 0-2-.9-2-2v-3c0-1.1.9-2 2-2h3Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
