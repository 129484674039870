import * as React from 'react';

export const ElectricRange = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Electric Range Small</title>
    <path
      d="M23.5 21.5h-14 14ZM9.5 19v5-5Zm14 0v5-5ZM7 22.5H1M12.5 18V3.5a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v19m8.5-12H4m15.5 0v7a2 2 0 1 1-4 0v-5a2 2 0 0 0-2-2h-1m10-6v3a3 3 0 0 1-6 0v-3h6Zm-5 0V1m4 3.5V1"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
