import * as React from 'react';

export const News = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>News Small</title>
    <path
      d="M.5 9.5h5v-5M3 15.5h10m-10-3h10m6.7-3h3.4l-5.9 10m-.2-12h3.2l-3 12M.5 9.5v10h15v-15h-10l-5 5Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
