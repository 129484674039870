import * as React from 'react';

export const Profile = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Profile Small</title>
    <path
      d="M4.2 9.8c2-.8 3.7-2.2 5-3.9 2.9-3.8 5.6-1.7 6.5 1.4.6 2.1 1.7 7-10.4 12.1M4.5 6.6l2.4-2.2m12.8 12.1c-1.7 4.1-4.3 5.9-7.2 6.8-1.7.5-3.6.2-5-.9l-1.2-1.1m13.5-8.5c-2.2.3-2 1.4-4.5 4.3-1.7 2-3.9 3.1-4.9 3.1H9.1M4.3 13c1.6-.6 3-1.5 4.3-2.6 2.6-2.2 3.3-3.5 4.3-2.2 1 1.3-.7 3.7-3.1 5.2-1.7 1.2-3.6 2.2-5.5 3.1M6.4 2.8C7.9 1.3 9.9.5 12 .5c4.5 0 7.5 4.5 7.5 9.3v.6"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
