import * as React from 'react';

export const Polls = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Polls Small</title>
    <path
      d="M1 23.5h22H1Zm16.5-18h5v15h-5v-15Zm-8-5h5v20h-5V.5Zm-8 9h5v11h-5v-11Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
