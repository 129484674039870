import * as React from 'react';

export const Hotline = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Hotline Small</title>
    <path
      d="M3.5 8.5h-.3c-1.5 0-2.7 1.9-2.7 4s1.2 4 2.7 4h.3m17-9h-2.1c-.7 0-.9.5-.9 1.1v7.7c0 .7.2 1.2.9 1.2h2.1V6.2c0-3.3-2.2-5.7-5.5-5.7H9C5.7.5 3.5 3 3.5 6.2v10.5c0 3.3 2 5.8 5.3 5.8h.7m0-1h6v2h-6v-2Zm-6-14h2.1c.7 0 .9.5.9 1.1v7.7c0 .7-.2 1.2-.9 1.2H3.5m17-9h.4c1.5 0 2.6 1.9 2.6 4s-1.1 4-2.6 4h-.4"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
