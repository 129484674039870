import * as React from 'react';

export const DocumentPDF = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Document PDF Small</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M19.5 20v2.5h-16v-20H15L19.5 7v3m-5-7.5v5h5"
        stroke={props.stroke || 'currentColor'}
      />
      <path
        d="M6.028 18v-5.792h2.288c1.784 0 2.424.744 2.424 1.8s-.68 1.832-2.464 1.832H6.852V18h-.824Zm2.112-5.12H6.852v2.288H8.14c1.336 0 1.76-.416 1.76-1.16s-.424-1.128-1.76-1.128ZM12.164 18v-5.792h2.304c2.032 0 3.168 1.128 3.168 2.896 0 1.704-1.184 2.896-3.168 2.896h-2.304Zm2.36-5.12h-1.536v4.448h1.536c1.52 0 2.272-.928 2.272-2.224 0-1.296-.752-2.224-2.272-2.224ZM19.18 18h.824v-2.464h3.008v-.672h-3.008V12.88h3.512v-.672H19.18V18Z"
        fill={props.stroke || 'currentColor'}
        fillRule="nonzero"
        strokeWidth="0.3"
      />
    </g>
  </svg>
);
