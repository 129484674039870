import * as React from 'react';

export const Documents = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Documents Small</title>
    <path
      d="M7 18.5h10m-10-3h10m-10-3h10m-2.5-10v5h5m0 0v14h-15v-19h10l5 5Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
