import * as React from 'react';

export const VoiceCall1 = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Voice Call 1 Small</title>
    <path
      d="m17.5 23.3 4-3.8c.2-.2.2-.8 0-1l-3-3c-.2-.2-.8-.2-1 0L16 16.8s-.7 1.1-4.9-3.1l-.5-.5C6.3 8.9 7.5 8.5 7.5 8.5l1-1c.2-.2.2-.8 0-1l-3-3c-.2-.2-.8-.2-1 0l-3.8 4s5.8 9.6 16.8 15.8Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
