import { CardsContentArr } from '../app/types/shared';
import { getRelatedElement } from './getRelatedElement';

export const getImpressionEvent = (cards: CardsContentArr) => {
  const getRelatedElements = () => {
    const renderedCards = cards.length >= 9 ? cards.splice(0, 9) : cards;
    return renderedCards.map((el, i) => getRelatedElement(el, i + 1));
  };

  return {
    eventInfo: {
      eventAction: 'impression',
      eventName: 'secondary links - impression',
    },
    attributes: {
      componentName: 'secondary-links',
      label: '',
      targetURL: '',
      clickID: '',
      elementName: '',
      value: '',
      pos: cards.length >= 9 ? '9' : cards.length.toString(),
      relatedElements: getRelatedElements(),
    },
  };
};
