import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { GroupCardContent } from '../app/types/shared';
import { getRelatedElement } from './getRelatedElement';

const fileExtRegex = /^.*\.(jpg|gif|doc|pdf|png)/gi;

export const getClickEventAction = (trackingService: TrackingServiceV2, sanitizedUrl: string) => {
  const evaluatedLink = trackingService.evaluateLinkType(sanitizedUrl);
  if (evaluatedLink) {
    return evaluatedLink;
  }

  if (sanitizedUrl?.includes('download') || sanitizedUrl?.match(fileExtRegex)) {
    return 'download';
  }
  return 'content';
};

export const getSanitizedUrl = (trackingService: TrackingServiceV2, card: GroupCardContent) => {
  return trackingService.sanitizeUrl(card.URL || '');
};

export const getClickEvent = (
  eventAction: string,
  sanitizedUrl: string,
  card: GroupCardContent,
  index: number,
) => {
  const position = index + 1;
  const targetURL =
    sanitizedUrl?.startsWith('#') && typeof window !== 'undefined'
      ? `${window.location.href.split('#')[0]}${sanitizedUrl}`
      : sanitizedUrl;
  return {
    eventInfo: {
      eventAction,
      eventName: 'secondary links - click on a link',
    },
    attributes: {
      componentName: 'secondary-links',
      label: card.cardTitle,
      targetURL,
      clickID: '',
      elementName: 'text link',
      value: '',
      pos: position.toString(),
      relatedElements: [getRelatedElement(card, position)],
    },
  };
};
