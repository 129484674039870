import * as React from 'react';

export const Notepad = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Notepad Small</title>
    <path
      d="M19.5 7v15.5h-16v-20H15L19.5 7Zm-5-4.5v5h5"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
