import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import React, { PropsWithChildren } from 'react';
import { TrackingServiceContext } from './TrackingServiceContext';

export const TrackingServiceContextProvider: React.FC<
  {
    trackingService: TrackingServiceV2;
  } & PropsWithChildren
> = ({ trackingService, children }) => {
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TrackingServiceContext.Provider value={{ trackingService }}>
      {children}
    </TrackingServiceContext.Provider>
  );
};

TrackingServiceContextProvider.displayName = 'TrackingServiceContextProvider';
