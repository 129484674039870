import * as React from 'react';

export const Charging = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Charging Small</title>
    <path
      d="M10.5 8.5H5.8s-2.2-.1-2.2 2 2.2 2 2.2 2H7c1 0 1.5.4 1.5 1.9V20m9-15.5H15s-4.5-.1-4.5 4 4.5 4 4.5 4h2.5v-8Zm0 6H21h-3.5Zm0-4H21h-3.5Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
