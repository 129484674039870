/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import { withAudiDesignSystemLight } from '@oneaudi/narown-utils-ui';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { SecondaryLinksContent } from './components/SecondaryLinksContent/index';
import { ContentContext } from './context/ContentContext';
import { useTrackingService } from './context/TrackingServiceContext';
import { readyEvent } from '../tracking/readyEvent';
import { getImpressionEvent } from '../tracking/impressionEvent';
import { getNextId } from '../tracking/utils';

const Container = withAudiDesignSystemLight(SecondaryLinksContent);

interface WrapperProps {
  configProvider: GfaServiceConfigProviderV1;
}

const FeatureApp: React.FC<WrapperProps> = () => {
  const context = useContext(ContentContext);
  const { content } = context;

  const { trackingService } = useTrackingService();

  const featureAppId = useMemo(
    () => trackingService?.featureAppId ?? getNextId(__FEATURE_APP_NAME__),
    [],
  );

  useEffect(() => {
    if (trackingService) {
      trackingService.track({
        event: readyEvent,
        componentUpdate: {
          componentInfo: {
            componentID: featureAppId,
          },
          attributes: { implementer: 2, version: __FEATURE_APP_VERSION__ },
        },
      });
    }
  }, []);

  const entryRef = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (node: any) => {
      if (node !== null && trackingService) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        trackingService.registerImpressionTracking(node, () => {
          return {
            event: getImpressionEvent(content.cards),
          };
        });
      }
    },
    [trackingService],
  );

  return (
    <div ref={entryRef}>
      <Container cardsData={content} />
    </div>
  );
};

export default FeatureApp;
