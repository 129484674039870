import * as React from 'react';

export const CarHandover = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Car Handover Small</title>
    <path
      d="m15.2 15.9 5.7-1.7s2.6-.6 2.6.9c0 1.2-1.2 1.7-2 2.1-2.9 1.3-7.4 3.4-7.4 3.4s-2.2.9-4.6.9h-9v-5c2.4 0 1.8.2 3.9-.9 1.3-.7 2.9-1.1 4.4-1.1h4.7c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5H7m1.5-7a1.5 1.5 0 0 1-3 0l-1.181-.152c-.438-.032-.85-.41-.819-.848l.216-2.006c.032-.457.353-.842.797-.956.283-.072.565-.131.848-.179.797-.132 2.39-1.195 3.453-1.593.544-.204 1.65-.27 2.686-.266.986.005 1.907.072 2.36.266.391.168 1.144.787 2.258 1.86 1.328.176 2.257.353 2.788.53.797.266 1.328.797 1.461 1.195.089.266.133.664.133 1.196l-.002.212a.664.664 0 0 1-.664.664L18.5 10.5a1.5 1.5 0 0 1-3 0h-7Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
