import React from 'react';
import { Text } from '@audi/audi-ui-react';
import { UeElement, UeReference } from '@oneaudi/falcon-tools';
import { ListItem, LinkCard, IconContainer, LinkIconContainer } from './styles';
import { ExternalLink, Forward } from '../Icon';
import { CardIcon } from './CardIcon';
import { CardContentProps } from '../../types/shared';
import { useTrackingService } from '../../context/TrackingServiceContext';
import { getClickEvent, getClickEventAction, getSanitizedUrl } from '../../../tracking/clickEvent';

export const SecondaryLinkCard: React.FC<CardContentProps> = ({ cardData, index }) => {
  const { trackingService } = useTrackingService();
  const onCardClick = (e: React.MouseEvent<HTMLElement>) => {
    if (trackingService) {
      const sanitizedUrl = getSanitizedUrl(trackingService, cardData);
      const eventAction = getClickEventAction(trackingService, sanitizedUrl);
      const event = getClickEvent(eventAction, sanitizedUrl, cardData, index);
      trackingService.track({ event });
      e.stopPropagation();
    }
  };

  const { showIcon, openLinkNewTab, URL, cardTitle, iconName } = cardData;

  let title = cardTitle;
  if (title.length > 44) {
    title = title.slice(0, 44);
  }

  return (
    <UeReference propertyPath={`cards.${index}`} behavior="component">
      {(ueRefProps) => (
        <ListItem {...ueRefProps}>
          <LinkCard
            data-testid="secondary-link-card"
            showIcon={showIcon}
            href={URL}
            target={openLinkNewTab ? '_blank' : '_self'}
            rel="noopener noreferrer"
            onClick={onCardClick}
          >
            <IconContainer showIcon={showIcon} data-testid="icon-container">
              <CardIcon stroke="#000" iconName={iconName} testid="card-icon" />
            </IconContainer>
            <Text variant="copy1" {...ueRefProps}>
              <UeElement type="text" property="cardTitle" label="Card Title">
                {title}
              </UeElement>
            </Text>
            <LinkIconContainer>
              {openLinkNewTab ? (
                <ExternalLink stroke="#666" data-testid="link-icon" />
              ) : (
                <Forward stroke="#666" data-testid="link-icon" />
              )}
            </LinkIconContainer>
          </LinkCard>
        </ListItem>
      )}
    </UeReference>
  );
};
