import * as React from 'react';

export const Editorial = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Editorial Small</title>
    <path
      d="M5 15.5h14H5Zm0-3h14H5Zm8-3h6-6Zm0-3h6-6Zm-7.5 0h5v3h-5v-3Zm16 17h-18c-.5 0-1-.4-1-1V.5h18c.5 0 1 .4 1 1v22Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
