import * as React from 'react';

export const ShoppingBasket = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Shopping Basket Small</title>
    <path
      d="M6.4 9.5h15.3M5 5.5h18l-2.5 8H7.9m11.6 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-9 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM0 1.5h3.5l5.5 15"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
