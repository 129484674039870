import * as React from 'react';

export const Tour = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Tour Small</title>
    <path
      d="M18.5 8.5c2.2 0 4 1.8 4 4s-4 8-4 8-4-5.8-4-8c-.1-2.2 1.6-3.9 3.8-4h.2Zm-15-6c1.1 0 2 .9 2 2s-2 4-2 4-2-2.9-2-4 .8-2 1.9-2h.1Zm9 0c1.1 0 2 .9 2 2s-2 4-2 4-2-2.9-2-4 .8-2 1.9-2h.1Zm-9.5 8h8.5l-7 5 14 7.5"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
