import * as React from 'react';

export const FuelStation = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Fuel Station Small</title>
    <path
      d="M16 22.5H3m11.5 0v-19a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v19m8.5-12H6M18.5 2c1.333 1.667 2.333 3.667 3 6v9.5a2 2 0 1 1-4 0v-5a2 2 0 0 0-2-2h-1"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
