import * as React from 'react';

export const ExternalLink = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-label="New Tab" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>New Tab</title>
    <path
      d="M17.5 11.594V20.5h-15v-15h7.969m4.031-3h6v6m-9 3 9-9-9 9Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
