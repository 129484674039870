import * as React from 'react';

export const Gift = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Gift Small</title>
    <path
      d="M2.5 2.5h17a1 1 0 0 1 1 1v17h-17a1 1 0 0 1-1-1v-17Zm0 9h18m-9 9v-18M16 16l-4.5-4.5L7 16m-.75-7.5a1.187 1.187 0 0 1 1.345-1.581L8 7a4.029 4.029 0 0 1 3.118 2.973L11.5 11.5l-2.415-.537A3.941 3.941 0 0 1 6.25 8.5Zm8.783-1.702.218-.026a1.251 1.251 0 0 1 1.282 1.776l-.094.199a4.32 4.32 0 0 1-2.859 2.346l-2.015.504.461-2.074a3.512 3.512 0 0 1 3.007-2.725Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
