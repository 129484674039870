import * as React from 'react';

export const Wallbox = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Wallbox Small</title>
    <path
      d="M22.5 4.5v3a3 3 0 0 1-6 0v-3h6Zm-5 0V1m4 3.5V1m-2 9.5v10a2 2 0 0 1-2 2H4a2 2 0 1 1 0-4h1.5a2 2 0 0 0 2-2v-2m-3-13h6a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2Zm.5 3h5"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
