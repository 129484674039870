export const readyEvent = {
  eventInfo: { eventAction: 'feature_app_ready', eventName: 'secondary links - feature app ready' },
  attributes: {
    componentName: 'secondary-links',
    label: '',
    targetURL: '',
    clickID: '',
    elementName: '',
    value: '',
    pos: '',
  },
};
