import * as React from 'react';

export const Power = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Power Small</title>
    <path
      d="m11.5 13.5 6.5 2m4.5-2H20m-.7-7.8-1.8 1.8m-6-5V5m-6 2.5L3.7 5.7M3 13.5H.5m5 6-1.8 1.8C1.6 19.2.5 16.4.5 13.5c0-6.1 4.9-11 11-11s11 4.9 11 11c0 3-1.2 5.8-3.2 7.8l-1.8-1.8"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
