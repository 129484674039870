import * as React from 'react';

export const Consumption = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Consumption Small</title>
    <path
      d="M18.5 15.4c.1 3.8-2.8 6.9-6.6 7.1h-.4c-3.9-.1-7-3.2-7-7.1 0-3.9 7-13.9 7-13.9s7 10.1 7 13.9Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
