import * as React from 'react';

export const CalendarEvents = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Calendar Events Small</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M5.5 19.5v3c0 .6.4 1 1 1h15c.6 0 1-.4 1-1V6M.5 3v15.5c0 .5.4 1 1 1h12l5-5V3m0 11.5h-5v5M0 .5h19"
        stroke={props.stroke || 'currentColor'}
      />
      <path
        d="m4 5.9 2.1-.5h.6v5.8h1.8v.8H4v-.8h1.8V6.3L4 6.7v-.8Zm5.7 5.8v-.8c.7.2 1.5.3 2.3.4 1.4 0 2-.5 2-1.2 0-1.2-2.7-1.4-4.1-1.4l.2-3.3h4.5v.8h-3.7L10.8 8c1.9.1 4.1.5 4.1 2.1 0 1.1-.9 2-3 2-.8 0-1.5-.2-2.2-.4Z"
        fill={props.stroke || 'currentColor'}
        fillRule="nonzero"
        strokeWidth="0.3"
      />
    </g>
  </svg>
);
