import * as React from 'react';

export const TrafficSignRecognitionAlert = ({
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Traffic Sign Recognition Alert</title>
    <path
      d="M23.2 12.5H11.6L17.5 1l5.7 11.5ZM17.5 5v3m0 1v2m2 4 3.7 7.5H.8L12 1.5 13.5 4M9 12.5l-2.5 3V19m2-2v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-.5h5v.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3l-2-2.5h-5l-2 2.5Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
