import { responsiveStyles } from '@audi/audi-ui-react';

import { ThemeProps } from '../../types/shared';

type BreakpointStyles = Parameters<typeof responsiveStyles>[0];

export const createMediaQuery =
  (styles: BreakpointStyles) =>
  (themeProps: ThemeProps): string => {
    return responsiveStyles(styles, themeProps.theme.breakpoints);
  };
