import * as React from 'react';

export const BusinessAddress = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Business Address Small</title>
    <path
      d="M22.5 6.5H.5v14c0 .6.5 1 1 1h22v-14c0-.5-.5-1-1-1ZM3 17.5h14M3.5 7V3.8c0-1.3 1-2.3 2.3-2.3h2.3c1.3 0 2.3 1 2.4 2.3v6.7c0 1.1-.9 3-2 3s-2-1.9-2-3V4"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
