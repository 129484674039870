import * as React from 'react';

export const Announcement = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Announcement Small</title>
    <path
      d="M11.5 5.5v8h-3l3 9h-4l-3-9h-4v-8h11Zm0 0c3.9 0 10-3 10-3 1.2 0 2 2.5 2 7s-.8 7-2 7c0 0-6.1-3-10-3"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
