import * as React from 'react';

export const Car = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Car Small</title>
    <path
      d="M.5 11.5v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-1.045l15 .045v1a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-8l-2-2c-.66-1.212-1.19-2.136-1.59-2.773-.4-.636-.87-1.363-1.41-2.182a78.437 78.437 0 0 0-6.488-.272c-2.135 0-4.305.09-6.512.272-.54.819-1.01 1.546-1.41 2.182-.4.637-.93 1.56-1.59 2.773l-2 2Zm6 2 1-1h9l1 1c-.636 2-1.136 3-1.5 3H8c-.364 0-.864-1-1.5-3Zm-4-4h19m-2.5 3h4.5m-23 0H5"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
