import * as React from 'react';

export const Distance = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Distance Small</title>
    <path
      d="m11.7 19.6-3.2 1.7V4.8l7-3.8 7 4.2v16l-3-1.7m-4-14.3V1m-7 20.3L.5 17V1l8 3.8v16.5Zm3-9.7c0-2.1 1.7-3.9 3.8-3.9h.2c2.2 0 4 1.8 4 4s-4 8.1-4 8.1-3.9-5.7-4-8v-.2Z"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
