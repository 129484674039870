import * as React from 'react';

export const Merchandise = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width={24} height={24} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Merchandise Small</title>
    <path
      d="M6.894 2.689A18.165 18.165 0 0 1 9.5 2.5h5c.868 0 1.737.063 2.605.189a3 3 0 0 1 .732.203l5.16 2.166L22 10l-3.502-1.329V20.5H5.502V8.671L2 10 1 5.058l5.163-2.167a3 3 0 0 1 .731-.202Zm8.606-.161a3.5 3.5 0 0 1-7 0"
      stroke={props.stroke || 'currentColor'}
      fill="none"
    />
  </svg>
);
